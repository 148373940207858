exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisory-relationship-summary-js": () => import("./../../../src/pages/advisory-relationship-summary.js" /* webpackChunkName: "component---src-pages-advisory-relationship-summary-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-news-article-manual-js": () => import("./../../../src/pages/news/article.manual.js" /* webpackChunkName: "component---src-pages-news-article-manual-js" */),
  "component---src-pages-news-news-template-js": () => import("./../../../src/pages/news/news.template.js" /* webpackChunkName: "component---src-pages-news-news-template-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

